import { useSession } from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";
import { useEffect, useMemo } from "react";

const EXPERIMENT_NAME = "HideFacebookSignup";
export function useHideFacebookSignupExperiment(): boolean {
  const { currentUserHasLoaded } = useSession();
  const { variant, trigger } = useExperiment({
    currentUserHasLoaded,
    experimentName: EXPERIMENT_NAME,
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  return useMemo(() => {
    return variant === "treatment";
  }, [variant]);
}
