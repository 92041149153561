import { CookieKeys } from "@outschool/data-schemas";
import Cookies from "js-cookie";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";
import { injectScript } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import type { Context } from "../types";

export default class OptiMonkPlugin implements AnalyticsPlugin {
  name = Integration.OptiMonk;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  scriptId = "optimonk";
  scriptSrc = "https://onsite.optimonk.com/script.js?account=240103";

  isLoadable() {
    // Only show exit survey if user is logged out
    // If user does NOT have a LoggedInUserExperimentUid, they are logged out
    return !Cookies.get(CookieKeys.LoggedInUserExperimentUid);
  }

  async load(): Promise<void> {
    await injectScript(this.scriptId, this.scriptSrc);
  }

  async unload(): Promise<void> {
    const script = document.getElementById(this.scriptId);
    script?.remove();
  }

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }

    return !!document.getElementById(this.scriptId);
  }

  async track(context: Context) {
    // Tracking is being handled through OptiMonk so don't catch any events
    return context;
  }
}
